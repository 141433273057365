import React from 'react';
import './styles/App.css';
import logo from './assets/logo.png'; // Adjust the path as needed
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider, useAuth } from './authContext';
import HomePage from './Home';
import Login from './Login';
import ChangePasswordPage from './ChangePassword';
import { useNavigate } from 'react-router-dom';

const ApplicationRoutes = () => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {        
    return (
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/changepassword" element={<ChangePasswordPage />} />
      </Routes>
    )
  } else {
    return (
      <Routes>
        <Route path="*" element={<Login />} />
      </Routes>
    )
  }  
}

function App() {      
  return (    
    <div className="App">          
      <AuthProvider>
        <Router>                  
          <ApplicationRoutes />
        </Router>
      </AuthProvider>
    </div>
  );
}

export default App;
