import React, { useState } from 'react';
import axios from 'axios';
import { useAuth } from './authContext';

import logo from './assets/logo.png'; // Adjust the path as needed

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { login } = useAuth();
    

    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        const response = await axios.post(`/login-verify`, { username, password });
        login(response.data.token);
      } catch (err) {
        console.error('Invalid credentials');
      }
    };
  
    return (
    <>
     <header>
        <img src={logo} alt="Claim Lion Law" />
        <h1>File Management Search</h1>        
    </header>
    
      <form onSubmit={handleSubmit}>
        <div>
          <label>Username:</label>
          <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
        </div>
        <div>
          <label>Password:</label>
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </div>
        <button type="submit">Login</button>
      </form>
      </>
    );
  };

export default Login;
