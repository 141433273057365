// downloadIcon.js
import React from 'react';

const DownloadIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24px"
    height="24px"
    fill="#FFFFFF" // Changed to white
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M5 20h14v-2H5v2zm7-18L5.33 11h3.62v4h6v-4h3.62L12 2zm0 5.66L10.06 9h3.88L12 7.66z" transform="rotate(180 12 12)"/>
  </svg>
);

export default DownloadIcon;
