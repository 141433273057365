// Home.js
import React, { useState, useMemo } from 'react';
import Loader from './Loader';
import axios from 'axios';
import logo from './assets/logo.png'; // Adjust the path as needed
import { useNavigate } from 'react-router-dom';
import { useAuth } from './authContext';
import DownloadIcon from './assets/downloadIcon';
import Select from 'react-select'; // Importing react-select
import './HomePage.css'; // Import the new CSS file

const HomePage = () => {
  const [query, setQuery] = useState('');
  const [type, setType] = useState('ca_reference');
  const [results, setResults] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [loading, setLoading] = useState(false);
  
  // Filters state
  const [selectedFileTypes, setSelectedFileTypes] = useState([]);
  const [selectedCARefs, setSelectedCARefs] = useState([]);

  const { logout } = useAuth();
  const navigate = useNavigate();

  // Handle Search Function
  const handleSearch = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/search`, {
        params: { query, type }
      });
      const fetchedResults = response.data;

      // Process results to mark direct matches
      const processedResults = fetchedResults.map(result => {
        let isDirectMatch = false;
        if (type === 'ca_reference' && result.ca_reference === query) {
          isDirectMatch = true;
        } else if (type === 'case_id' && result.source_ref === query) {
          isDirectMatch = true;
        } else if (type === 'proclaim_id' && result.proclaim_ref === query) {
          isDirectMatch = true;
        }
        return { ...result, isDirectMatch };
      });

      // Remove duplicates
      const uniqueResults = removeDuplicates(processedResults);

      // Sort results: direct matches first
      const sortedResults = sortResults(uniqueResults);

      setResults(sortedResults);
      setFilteredResults(sortedResults); // Initialize filtered results
    } catch (error) {
      console.error('Error making the request', error);
      setResults([]);
      setFilteredResults([]);
    } finally {
      setLoading(false);
    }
  };

  // Remove Duplicates Function
  const removeDuplicates = (results) => {
    const uniqueMap = new Map();
    results.forEach(result => {
      const key = `${result.file_type}-${result.ca_reference}-${result.file_name}`;
      if (!uniqueMap.has(key)) {
        uniqueMap.set(key, result);
      }
    });
    return Array.from(uniqueMap.values());
  };

  // Sort Results Function
  const sortResults = (results) => {
    return results.sort((a, b) => {
      if (a.isDirectMatch && !b.isDirectMatch) return -1;
      if (!a.isDirectMatch && b.isDirectMatch) return 1;
      return a.file_type.localeCompare(b.file_type);
    });
  };

  // Handle Filters
  const handleFileTypeFilter = (selectedOptions) => {
    setSelectedFileTypes(selectedOptions || []);
  };

  const handleCARefFilter = (selectedOptions) => {
    setSelectedCARefs(selectedOptions || []);
  };

  // Apply Filters whenever selected filters or results change
  useMemo(() => {
    let tempResults = [...results];

    // Filter by File Type
    if (selectedFileTypes.length > 0) {
      const fileTypes = selectedFileTypes.map(option => option.value);
      tempResults = tempResults.filter(result => fileTypes.includes(result.file_type));
    }

    // Filter by CA Reference
    if (selectedCARefs.length > 0) {
      const caRefs = selectedCARefs.map(option => option.value);
      tempResults = tempResults.filter(result => caRefs.includes(result.ca_reference));
    }

    setFilteredResults(tempResults);
  }, [selectedFileTypes, selectedCARefs, results]);

  // Get unique File Types and CA References for filter options
  const fileTypeOptions = useMemo(() => {
    const types = [...new Set(results.map(result => result.file_type))];
    return types.map(type => ({ value: type, label: type }));
  }, [results]);

  const caRefOptions = useMemo(() => {
    const caRefs = [...new Set(results.map(result => result.ca_reference).filter(ref => ref))];
    return caRefs.map(ref => ({ value: ref, label: ref }));
  }, [results]);

  // Handle Form Submission
  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleSearch();
  };

  // Handle File Download
  const fileDownload = async (file_path) => {
    const downloadUrl = `/proxy/files/${file_path}`;
    window.open(downloadUrl, '_blank');
  }

  return (
    <>      
      <header>
          <img src={logo} alt="Claim Lion Law" />
          <h1>File Management Search</h1>
          <div className="header-buttons">
            <button onClick={logout}>Logout</button>
            <button onClick={() => navigate('/changepassword')}>Change Password</button>
          </div>
      </header>
      
      <form onSubmit={handleFormSubmit} className="search-form">
        <label>
          Search Type:
          <select value={type} onChange={e => setType(e.target.value)}>
            <option value="ca_reference">CA Reference</option>
            <option value="case_id">Case ID</option>
            <option value="proclaim_id">Proclaim ID</option>              
            <option value="email">Email</option>
          </select>
        </label>
        <label>
          Query:
          <input 
            type="text" 
            value={query} 
            onChange={e => setQuery(e.target.value)} 
            required
          />
        </label>
        <button type="submit">Search</button>
      </form>

      {/* Filters Section */}
      {results.length > 0 && (
        <div className="filters">
          <div className="filter">
            <label>File Type:</label>
            <Select
              isMulti
              options={fileTypeOptions}
              value={selectedFileTypes}
              onChange={handleFileTypeFilter}
              placeholder="Select File Types"
              className="select-dropdown"
              isClearable
            />
          </div>
          <div className="filter">
            <label>CA Reference:</label>
            <Select
              isMulti
              options={caRefOptions}
              value={selectedCARefs}
              onChange={handleCARefFilter}
              placeholder="Select CA References"
              className="select-dropdown"
              isClearable
            />
          </div>
        </div>
      )}

      {loading ? (
        <Loader />
      ) : (
        <div className="results">
          <h2>Results</h2>
          {filteredResults.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th className="thr1">File Type</th>
                  <th className="thr2">Download File</th>
                  <th className="thr3">CA Reference</th>
                  <th className="thr4">File Name</th>
                </tr>
              </thead>
              <tbody>
                {filteredResults.map((result, index) => (
                  <tr key={index}>
                    <td className="thr1">{result.file_type}</td>
                    <td className="thr2 linkrow">                        
                      <button onClick={() => fileDownload(result.file_path)}>
                        <DownloadIcon/>
                      </button>
                    </td>
                    <td className="thr3">{result.ca_reference || 'N/A'}</td>
                    <td className="thr4">{result.file_name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No results found</p>
          )}
        </div>
      )}
    </>
  );
};

export default HomePage;
