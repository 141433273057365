import React, { useState } from 'react';
import axios from 'axios';
import { useAuth } from './authContext';
import { useNavigate } from 'react-router-dom';
import logo from './assets/logo.png'; // Adjust the path as needed
const ChangePasswordPage = () => {
  const [newPassword, setNewPassword] = useState('');
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    try {
      await axios.post('/change-password', { token, newPassword });
      alert('Password changed successfully');
      navigate('/');
    } catch (err) {
      console.error('Error changing password');
    }
  };

  if (!isAuthenticated) {
    return <div>Please login to change your password.</div>;
  }

  return (
    <>
    <header>
        <img src={logo} alt="Claim Lion Law" />
        <h1>File Management Search</h1>
        <button onClick={() => navigate('/')}>Home</button>        
    </header>
    
    <form onSubmit={handleSubmit}>
      <div>
        <label>New Password:</label>
        <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
      </div>
      <button type="submit">Change Password</button>
    </form>
    </>
  );
};

export default ChangePasswordPage;
